import { Button, Input,Row } from "antd"
import { FC } from "react"

//icons
import Search from '../../assets/images/icons/search.svg'
import ChatDots from '../../assets/images/icons/chat-dots.svg'
import Database from '../../assets/images/icons/database.svg'
import Bell from '../../assets/images/icons/bell.svg'
import ArrowDown from '../../assets/images/icons/arrow-down.svg'
import User from '../../assets/images/app/user.png'

import { grayColor,grayColor2,lightGray,lightGray2,whiteColor } from "src/constants/Colors"

const Header: FC<{ renderMenu?: FC }> = ({}) => {
  return (
    <div style={{ display: "flex", justifyContent: 'space-between'}}>
      {/* <div />
      <div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{display: 'flex', cursor: 'pointer'}}>
            <img src={ChatDots} alt=""/>
            <p style={{
              fontWeight: 200,
              fontSize: 16,
              color: grayColor2,
              marginLeft: 8,
            }}>Need help</p>
          </div>
          <div style={{marginLeft: 20, display: 'flex'}}>
            <div style={{width: 1, margin: '0 20px 0 20px', height: 26, backgroundColor: lightGray2}}></div>
            <img style={{cursor: 'pointer'}} src={Bell} alt="" />
            <div style={{width: 1, margin: '0 20px 0 20px', height: 26, backgroundColor: lightGray2}}></div>
          </div>
          <div>
            <img width={50} height={50} src={User} alt="" />
          </div>
          <div style={{marginLeft: 10, cursor: 'pointer'}}>
            <img src={ArrowDown} alt="" />
          </div>
      </div> */}
    </div>
  )
}

export { Header }
