import React, { useState } from 'react';
//images
import Logo from '../../assets/images/app/logo-white.png'
import LogoWhite from '../../assets/images/icons/logo-white.svg'
import ArrowUp from '../../assets/images/icons/arrow-up.svg'
import Home from '../../assets/images/icons/home-menu.svg'
import HomeWhite from '../../assets/images/icons/home-menu-white.svg'
import UserMenu from '../../assets/images/icons/profile-fill-menu.svg'
import UserMenuWhite from '../../assets/images/icons/profile-fill-menu-white.svg'
import UserMenuStar from '../../assets/images/icons/profile-fill-star.svg'
import UserMenuStarWhite from '../../assets/images/icons/profile-fill-star-white.svg'
import UserMenuCross from '../../assets/images/icons/profile-fill-cross.svg'
import UserMenuCrossWhite from '../../assets/images/icons/profile-fill-cross-white.svg'
import CalendarAdd from '../../assets/images/icons/event-calendar-menu.svg'
import CalendarAddWhite from '../../assets/images/icons/event-calendar-menu-white.svg'
import UsersGroup from '../../assets/images/icons/users-group-menu.svg'
import UsersGroupWhite from '../../assets/images/icons/users-group-menu-white.svg'
import EventList from '../../assets/images/icons/event-list-menu.svg'
import EventListWhite from '../../assets/images/icons/event-list-menu-white.svg'
import Payment from '../../assets/images/icons/payment-menu.svg'
import PaymentWhite from '../../assets/images/icons/payment-menu-white.svg'
import Report from '../../assets/images/icons/alert-fill-menu.svg'
import ReportWhite from '../../assets/images/icons/alert-fill-menu-white.svg'
import Help from '../../assets/images/icons/help-manu.svg'
import Search from '../../assets/images/icons/search-gray.svg'
import LogUotMenu from '../../assets/images/icons/log-out-menu.svg'
import User from '../../assets/images/app/user.png'
import BellMenu from '../../assets/images/icons/bell-gray-menu.svg'
import FileFillWhite from '../../assets/images/icons/file-fill-white.svg'
import FileFill from '../../assets/images/icons/file-fill.svg'
//colors
import { blackColor, blackColor2, grayColor10, grayColor9, orangeColor, redColor, whiteColor, whiteTransparent40 } from 'src/constants/Colors';
//navigate
import {useNavigate} from "react-router-dom";
import { ROUTES } from 'src/constants/routes';
import { useMenu } from 'src/MenuContext';
import { useCounterUserRequests } from 'src/store/counterUserRequests';
import { useCounterEventRequests } from 'src/store/counterEventRequests';
import { useCounterUserUnregistreted } from 'src/store/counterUnregistretedUsers';
import { useCounterUserReviewed } from 'src/store/counterUserReviewd';
import { useCounterEventReviewed } from 'src/store/counterEventReviwed';
import { useCounterUserRequestsGuest } from 'src/store/counterUserRequestsGuest';
import { useCounterUserUnregistretedCanBeApproved } from 'src/store/counterUnregistretedUsersCanBeApproved';
import { Tooltip } from 'antd';

const mockDataMenu = [
  {
    id: 0,
    title: 'Dashboard',
    icon: Home,
    iconActive: HomeWhite,
    link: ROUTES.DASHBOARD
  }, 
  {
    id: 1,
    title: 'Hosts Access Requests',
    icon: UserMenu,
    iconActive: UserMenuWhite,
    link: ROUTES.USERS,
  },
  {
    id: 2,
    title: 'Guests Access Requests',
    icon: UserMenuStar,
    iconActive: UserMenuStarWhite,
    link: ROUTES.USERS_NEW_GUEST,
  },
  // {
  //   id: 3,
  //   title: 'Unregistered Users Can Approved',
  //   icon: UserMenuCross,
  //   iconActive: UserMenuCrossWhite,
  //   link: ROUTES.UNREGISTERED_USERS_CAN_APPROVED,
  // },
  {
    id: 4,
    title: 'Unregistered Users',
    icon: UserMenuCross,
    iconActive: UserMenuCrossWhite,
    link: ROUTES.UNREGISTERED_USERS,
  },
  {
    id: 5,
    title: 'New Event Submissions',
    icon: CalendarAdd,
    iconActive: CalendarAddWhite,
    link: ROUTES.NEW_EVENT,
  },
  {
    id: 6,
    title: 'User Management',
    icon: UsersGroup,
    iconActive: UsersGroupWhite,
    link: ROUTES.USER_MANAGEMENT,
  },
  {
    id: 7,
    title: 'Event Management',
    icon: EventList,
    iconActive: EventListWhite,
    link: ROUTES.EVENT_MANAGEMENT,
  },
  {
    id: 8,
    title: 'Subscriptions',
    icon: Payment,
    iconActive: PaymentWhite,
    link: ROUTES.SUBSCRIPTIONS,
  },
  {
    id: 9,
    title: 'Reports',
    icon: Report,
    iconActive: ReportWhite,
    link: ROUTES.REPORTS,
  }
]

const SideMenu: React.FC = () => {
  const [activeItem, setActiveItem] = useState<number>(0);
  const navigate = useNavigate();

  const { collapseMenu, toggleCollapseMenu } = useMenu();

  const counterUserRequests = useCounterUserRequests((i: any) => i?.counterUserRequests);
  const counterUserRequestsGuest = useCounterUserRequestsGuest((i: any) => i?.counterUserRequestsGuest);
  const counterEventRequests = useCounterEventRequests((i: any) => i?.counterEventRequests);
  const counterUnregistretedUser = useCounterUserUnregistreted((i: any) => i?.counterUnregistretedUser);
  const counterUserReviewed = useCounterUserReviewed((i: any) => i?.counterUserReviewed);
  const counterEventReviewed = useCounterEventReviewed((i: any) => i?.counterEventReviewed);
  const counterUnregistretedUserCanBeApproved = useCounterUserUnregistretedCanBeApproved((i: any) => i?.counterUnregistretedUserCanBeApproved);
  
  const handleClickItem = (id: number, link: string) => {
    setActiveItem(id);
    navigate(link)
  }

  const handleCollapseMenu = () => {
    toggleCollapseMenu()
  }

  return (
    <div style={{
      position: 'sticky',
      top: 0,             
      minWidth: collapseMenu? 100: 350, 
      backgroundColor: blackColor,
      height: '100vh',
      padding: '46px 0',
      overflow: 'auto'
      }}>
      <div style={{
        display: 'flex', 
        justifyContent: collapseMenu? 'center': 'space-between',
        alignItems: 'center',
        marginBottom: 66,
        padding: collapseMenu? 0: '0 38px',
        position: 'relative'
      }}>
        <img width={collapseMenu? 30: 180} height={30} src={collapseMenu? LogoWhite: Logo}/>
        {/* <img onClick={handleCollapseMenu} style={{position: collapseMenu? 'absolute': 'relative', right: collapseMenu? 15: 0, cursor: 'pointer', marginLeft: collapseMenu? 8: 0}} width={12} height={12} src={collapseMenu? ArrowRight: ArrowLeft}/> */}
      </div>
      <div>
        <Tooltip title="Data export" placement="right">
        <div style={{
          display: 'flex',
          alignItems: 'center', 
          padding: '20px 38px',
          borderTop: `1px solid ${grayColor9}`,
          borderBottom: `1px solid ${grayColor9}`,
          marginBottom: 24,
          cursor: 'pointer'
        }}>
          <div style={{
             minHeight: 24,
             minWidth: 24, 
             display: 'flex',
             alignItems: 'center', 
             justifyContent: 'center', 
             borderRadius: 24, 
             backgroundColor: whiteColor,
             }}>
            <img src={ArrowUp} alt="" />
          </div>
          {!collapseMenu && <p style={{marginLeft: 16, fontSize: 14, color: whiteColor, fontWeight: 500}}>Data export</p>}
        </div>
        </Tooltip>
        {mockDataMenu.map(item => (
          <Tooltip title={item.title} placement="right">
            <div onClick={() => handleClickItem(item.id, item.link)} key={item.id} style={{
            display: 'flex', 
            alignItems: 'center',
            cursor: 'pointer',
            padding: collapseMenu? '7px 38px': '15px 38px',
            position: 'relative',
            backgroundColor: item.id === activeItem? blackColor2: ''
            }}>
            <img width={24} height={collapseMenu? 36: 24} src={item.id === activeItem? item.iconActive: item.icon}/>
            {!collapseMenu && <p style={{color: item.id === activeItem? whiteColor: whiteTransparent40, textOverflow: 'ellipsis', fontSize: 14, fontWeight: 200, marginLeft: 16}}>
            {item.title.length > 25 ? `${item.title.substring(0, 25)}...` : item.title}</p>}
            {(
              item.id === 1 ||
              item.id === 3 ||
              item.id === 2 || 
              item.id === 4 || 
              item.id === 5 || 
              item.id === 6 || 
              item.id === 7) &&
              <div style={{
                padding: '2px 4px',
                // width: 22, 
                // height: 22, 
                borderRadius: 4, 
                backgroundColor: grayColor10, 
                position: 'absolute',
                right: !collapseMenu? 38: 'auto',
                left: !collapseMenu? 'auto': 44,
                top: !collapseMenu? 'auto': 4,
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                marginLeft: 5
                }}>
                {item.id === 1 && <p style={{color: whiteColor, fontSize: 11, fontWeight: 500}}>{counterUserRequests}</p>}
                {item.id === 2 && <p style={{color: whiteColor, fontSize: 11, fontWeight: 500}}>{counterUserRequestsGuest}</p>}
                {item.id === 5 && <p style={{color: whiteColor, fontSize: 11, fontWeight: 500}}>{counterEventRequests}</p>}
                {item.id === 4 && <p style={{color: whiteColor, fontSize: 11, fontWeight: 500}}>{counterUnregistretedUser + counterUnregistretedUserCanBeApproved}</p>}
                {item.id === 6 && <p style={{color: whiteColor, fontSize: 11, fontWeight: 500}}>{counterUserReviewed}</p>}
                {item.id === 7 && <p style={{color: whiteColor, fontSize: 11, fontWeight: 500}}>{counterEventReviewed}</p>}
                {/* {item.id === 3 && <p style={{color: whiteColor, fontSize: 11, fontWeight: 500}}>{counterUnregistretedUserCanBeApproved}</p>} */}
              </div>
            }
          </div>
          </Tooltip>
        ))}
        <div style={{marginTop: 40}}>
        <Tooltip title="Testflight Hub" placement="right">
            <div onClick={() => handleClickItem(10, ROUTES.TEST_FLIGHT_HUB)} style={{display: 'flex', 
              alignItems: 'center', 
              cursor: 'pointer',
              padding: collapseMenu? '7px 38px': '15px 38px',
              }}>
                <img width={24} height={collapseMenu? 36: 24} src={activeItem === 10? FileFillWhite: FileFill}/>
                {!collapseMenu && <p style={{
                  color: activeItem === 10? whiteColor: whiteTransparent40, fontSize: 14, fontWeight: 200, marginLeft: 16
                }}>Testflight Hub</p>}
            </div>
          </Tooltip>
          <Tooltip title="Support" placement="right">
            <div style={{display: 'flex', 
              alignItems: 'center', 
              cursor: 'pointer',
              padding: collapseMenu? '7px 38px': '15px 38px',
              }}>
                <img width={24} height={collapseMenu? 36: 24} src={Help}/>
                {!collapseMenu && <p style={{
                  color: whiteTransparent40, fontSize: 14, fontWeight: 200, marginLeft: 16
                }}>Support</p>}
            </div>
          </Tooltip>
          <Tooltip title="Search" placement="right">
          <div style={{display: 'flex', 
            alignItems: 'center', 
            cursor: 'pointer',
            padding: collapseMenu? '7px 38px': '15px 38px',
            }}>
              <img width={24} height={collapseMenu? 36: 24} src={Search}/>
              {!collapseMenu && <p style={{
                color: whiteTransparent40, fontSize: 14, fontWeight: 200, marginLeft: 16
              }}>Search</p>}
          </div>
          </Tooltip>
        </div>
        <div style={{borderTop: `1px solid ${grayColor9}`, marginTop: 40}}>
          <div onClick={handleCollapseMenu} style={{display: 'flex', cursor: 'pointer', padding: '20px 38px', alignItems: 'center'}}>
              <img style={{transform: collapseMenu? 'rotate(180deg)': 'none'}} src={LogUotMenu} alt="" />
              {!collapseMenu && <p style={{color: whiteTransparent40, fontSize: 14, fontWeight: 400, marginLeft: 16}}>Minimize Menu</p>}
          </div>
          <div style={{display: 'flex', alignItems: 'center', marginLeft: 28, justifyContent: 'space-between'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <img style={{width: 40, height: 40}} src={User} alt="" />
              {!collapseMenu && <div style={{marginLeft: 12}}>
                <p style={{fontWeight: 500, color: whiteColor, fontSize: 14}}>Wayne Gunther</p>
                <p style={{fontWeight: 500, color: whiteTransparent40, fontSize: 14}}>wayne@gmail.com</p>
              </div>}
            </div>
            {!collapseMenu && <div style={{marginRight: 28, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <img style={{}} src={BellMenu} alt="" />
              <div style={{position: 'absolute', display: 'flex', alignItems:'center', justifyContent: 'center', top: -8, backgroundColor: redColor, width: 22, height: 22, borderRadius: 22}}>
                <p style={{color: whiteColor}}>0</p>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;