import { create } from 'zustand';

interface CounterState {
  counterUnregistretedUserCanBeApproved: number;
  setCounterUnregistretedUserCanBeApproved: (value: number) => void;
}

export const useCounterUserUnregistretedCanBeApproved = create<CounterState>((set) => ({
  counterUnregistretedUserCanBeApproved: 0,
  setCounterUnregistretedUserCanBeApproved: (value: number) => set({ counterUnregistretedUserCanBeApproved: value }),
}));
