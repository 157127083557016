const whiteColor: string = 'rgba(255, 255, 255, 1)';
const whiteTransparent40: string = 'rgba(255, 255, 255, 0.4)';
const blackColor: string = 'rgba(0, 0, 0, 1)';
const blackColor1: string = '#131313';
const blackColor2: string = '#1b1b1b';
const blackColor3: string = '#1F1F1F';
const blackColorOpacity6: string = 'rgba(0, 0, 0, 0.6)';
const grayColor: string = '#585858';
const lightGray: string = '#F0F0F0'
const lightGray1: string = 'RGB(243 243 243)'
const lightGray2: string = '#D0D0D0';
const lightGray3: string = '#F7F9FB';
const lightGray4: string = '#FAFBFD';
const lightGray5: string = '#F1F1F1';
const lightGray6: string = 'RGB(249 249 249)';
const lightGray7: string = 'RGBA(117, 117, 117, 0.12)';
const grayColor1: string = '#A1A1A1';
const grayColor2: string = '#949494';
const grayColor3: string = '#D0D1D2';
const grayColor4: string = '#CECED0';
const grayColor7: string = '#757575';
const grayColor8: string = 'rgba(75,75,75,0.64)';
const grayColor9: string = '#242424';
const grayColor10: string = '#292929';
const grayColor5: string = '#D2D2D2';
const grayColor6: string = '#E5E5E5';
const redColor: string = '#C63434';
const orangeColor: string = '#F64D4D';
const yellowColor: string = '#F0A066';
const greenColor: string = '#12B162';
const blueColor: string = '#8AB4D9';
const violetColor: string = '#8A8CD9';

export {
    whiteColor, 
    blackColor, 
    blackColor1, 
    grayColor, 
    grayColor1,
    grayColor2,
    grayColor3,
    grayColor4,
    grayColor5,
    grayColor6,
    grayColor7,
    grayColor9,
    lightGray,
    lightGray1,
    lightGray2,
    lightGray3,
    lightGray4,
    lightGray5,
    lightGray6,
    lightGray7,
    grayColor8,
    redColor,
    blackColor2,
    blackColorOpacity6,
    blackColor3,
    orangeColor,
    greenColor,
    blueColor,
    violetColor,
    yellowColor,
    whiteTransparent40,
    grayColor10
}