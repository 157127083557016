export const ROUTES = {
  LOGIN: "/",
  DASHBOARD: "/dashboard",
  USERS: "/users",
  USERS_NEW_GUEST: "/usersGuest",
  USER_DETAILS: "/usersDetails",
  EDIT_USER: "/editUser",
  UNREGISTERED_USERS: '/unregisteredUsers',
  UNREGISTERED_USERS_CAN_APPROVED: '/unregisteredUsersCanApproved',
  NEW_EVENT: "/newEvent",
  EVENT_DETAILS: "/eventDetails",
  EDIT_EVENT: "/editEvent",
  USER_MANAGEMENT: "/userManagement",
  EVENT_MANAGEMENT: "/eventManagement",
  SUBSCRIPTIONS: "/subscriptions",
  REPORTS: "/reports",
  TEST_FLIGHT_HUB: "/testFlightHub",
} as const;